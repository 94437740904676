// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
body {
  font-family: 'Fira Sans', sans-serif;
}

p {
  padding: 0;
  margin: 0
}

a {

}

.logout-button {
  text-align: right;
}

a:focus, a:hover {
  color: #23527c;
  text-decoration: underline;
}

.from {
  height: 1px
}

.container .content {
  width: 300px;
  margin-left: auto;
  margin-right: auto;

}

.container .content h1 {
  margin-bottom: 30px;
  font-weight: 400;
  font-size: 36px;
}

.container .content h2 {
  margin-bottom: 30px;
  font-weight: 300;
  font-size: 24px;
}

.container .content h3 {
  font-weight: 300;
  font-size: 24px;
}


.container .content p {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
}

.contact {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.contact-details div {
  min-width: 300px;
  margin-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.info {
  font-size: 13px;
  color: #565656;
  margin-left: 10px;
  padding: 5px;
}

.form-hidden {
  display: none;
}

#add-user, #add-team {
  margin-bottom: 10px;
}

.jumbotron {
  text-align: center;
}

.jumbotron p {
  font-weight: 300;
}

#team-helper-box {

}

#team-helper-box a {
  display: block;
}

.wide-page {
  .container {
    width: 100% !important;
  }

  .dataTables_filter {
    margin-top: 20px !important;
    text-align: left !important;
  }
}

.idrettshjelp {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  background: #FFFFFF;
  box-shadow: 0 3px 4px 0 rgba(158,158,158,0.50);
}

.top-logo {
  position: relative;
  height: 380px;
  width: 100%;
  background: url('/images/top-banner.jpg');
}

.top-logo-text {
  position: absolute;
  top: 80px;
  left: 80px;
  font-size: 36px;
  color: #FFFFFF;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.50);
}

.top-logo-text p {
  margin: 0;
  padding: 0;
  font-weight: 500;
  font-size: 36px;
}

.top-logo-text:focus, .top-logo-text:hover {
  color: #fff;
}

.contact-container {
  margin-left: auto;
  margin-right: auto;
  width: 600px;
}

.utkjoring {
  position: relative;
  display: block;
  height: 782px;
  background-image: url('/images/fotball-2.jpg');
}

.utkjoring-text {
  position: absolute;
  top: 175px;
  left: 175px;
  background-color: #fff;
  padding: 50px;
  font-weight: 400;
  font-size: 18px;
  color: #35241D;
  width: 320px;
}

footer {
  position: relative;
  background-color: #484848;
  font-size: 18px;
  color: #F1F1F1;
  box-shadow: 0 3px 4px 0 rgba(158,158,158,0.50);
}

footer a {
  color: #F1F1F1 !important;
}

.order-complete-container {
  text-align: center;
  margin-bottom: 30px;
}

.navbar-mobile {
  display: none;
}

@media (min-width: 1000px) {

  .container .content {
    width: 650px;
  }

  footer .footer-details {
    padding: 80px;

  }

  .navbar {
    margin-top: -70px;
    .container {

    }
  }

  .center {
    text-align: center;
  }

  .idrettshjelp-tall-input {
    width: 50px;
  }

  .navbar-nav {
    float: none !important;
  }

  .navbar-nav li {
    position: relative;
    float: none !important;

    background-color: #656889;
    width: 180px;
    height: 180px;
    overflow: hidden;
    display: inline-block !important;

    margin-left: 15px;
    margin-right: 15px;
    margin-top: 2px;
    margin-bottom: 2px;

    vertical-align: top;
  }

  .navbar-nav li a {
    height: 100%;
    width: 100%;
    font-size: 16px;
    color: #FFFFFF !important;
    padding: 0 !important;
    margin: 0 !important;
    text-align: center;
  }

  .navbar-nav li.active {
    width: 184px;
    height: 184px;
    background-color: #8186BE;
    border: 2px solid #363A67;
    margin-left: 13px;
    margin-right: 13px;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .navbar-nav li.active:after {
    border-right: 500px solid #6F73A3;
  }



  .navbar-nav li a:focus, .navbar-nav li a:active, .navbar-nav li a:hover {
    background-color: transparent !important;
  }
  
  .navbar-nav li:active, .navbar-nav li:hover {
    background-color: #8789b6 !important;
  }

  .navbar-nav li:active:after, .navbar-nav li:hover:after {
    border-right: 500px solid #767aaa;
  }

  .navbar-nav li a p {
    width: 100%;
    position: absolute;
    bottom: 32px;
    text-align: center;
  }

  .navbar-nav li a img {
    margin-top: 30px;
  }

  .triangle-down-right {
    width: 10%;
    height: 0;
    padding-top: 10%;
    overflow: hidden;
  }

  .navbar-nav li:after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    margin-top: -500px;
    border-top: 500px solid transparent;
    border-right: 500px solid #525576;
  }
}

@media (max-width: 1000px) {

  .container {
    margin-top: 20px;
  }

  .footer-details {
    padding: 20px;
  }


  .top-logo {
        height: auto;
      background: #2E95DA none;
    }

    .top-logo-text {
      position: relative;
      top: 0;
      left: 0;
      padding: 5px;
      display: block;
      text-align: center;
    }

    .top-logo-text p {
      font-size: 28px;
      color: #fff;
    }

  .utkjoring {
    height: auto;
    padding: 20px;
    width: 100%;
    margin: 0;
    box-sizing: border-box;
    left: 0;
  }

  .utkjoring-text {
    position: relative;
    top: 0;
    left: 0;
    box-sizing: border-box;
  }

  .home {
    text-align: center;
  }

  h1 {
    text-align: center;
    font-size: 22px !important;
  }

  h2 {
    font-size: 18px !important;
  }

  h3 {
    font-size: 16px !important;
  }

  .navbar {
    display: none;
  }

  .navbar-mobile {
    display: block;
    margin-top: 20px;
    margin-bottom: 20px;

  }

  .hamburger {
    font-size: 20px;
    margin-left: 10px;
  }

  .hamburger-box {
    height: 20px;
  }

  .navbar-nav {
    display: none;
    padding-left: 30px;
  }

  .center {
    text-align: center;
  }

  .navbar-nav>li>a {
    font-size: 20px;
  }

  .nav>li {
    width: 90%
  }

  .nav>li.active {
    background-color: #f7f7f7;

  }

  .nav > li.active a {
    color: #333 !important;
  }

  .nav>li>a>img {
    max-width: 30px;
    margin-right: 10px;
  }

  .navbar-nav > li > a:active,
  .navbar-nav > li > a:hover {
    background: #fdfdfd;
    color: #2E95DA;
    outline: #2E95DA 1px solid;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
  }

  .contact-container {
    width: 100%;
  }

  .nav p {
    display: inline-block;
  }
}